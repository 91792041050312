$red: #F44336;
$orange: #FF9800;
$warning: #FFEACC;
$blue: #03A9F4;
$black: #000000;
$yellow: #FFC107;
$blue_hover: #0091ea;
$blue_tag: #02587f;
$background: #eff4f7;
$border: #C6D2D9;
$light_text: #C6D2D9;
$lighter_text: #999999;
$middle_light_text: #666666;
$entries_header: #E4EAEE;
$dark_text: #333333;
$tags: #e1f5fe;
$green: #8bc34a;
$disabled: #C6D2D9;
$input-background: #FFFFFF;
$hover-selection: #E4EAEE;
$task-background: #F2F6F8;

$bg-12: #12191D;
$bg-11: #1D272C;
$bg-10: #263238;
$bg-09: #37474F;
$bg-04: #90A4AE;
$bg-03: #C6D2D9;
$bg-02: #E4EAEE;