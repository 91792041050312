@use "variables";

.tag-list {

  box-sizing: border-box;
  position: relative;
  width: 100%;

  &-name {
    display: inline-block !important;
    padding: 0px 5px !important;
    width: 85% !important;
    font-size: 14px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;    
  }

  &-button {
    // padding: 6px 0;
    padding: 0px 6px;
    background: white;
    border: 1px solid variables.$border;
    border-radius: 2px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 38px;

    &-required {
      //padding: 5px 0;
      padding: 0px 6px;
      background: white;
      border: 1px solid variables.$red;
      border-radius: 2px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 38px;
    }

    &-offline {
      width: 100%;
      height: 38px;
      // height: 40px;
      background: disabled;
      border: 1px solid variables.$border;
      border-radius: 2px;
      cursor: not-allowed;
      box-sizing: border-box;
      padding: 0px 6px;
      display: flex;
      align-items: center;
    }

    &-disabled {
      width: 100%;
      // height: 40px;
      height: 38px;
      background: #eff4f7;
      border: 1px solid variables.$border;
      border-radius: 2px;
      cursor: not-allowed;
      padding: 0px 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      }    
  }

  &-arrow {
    // position: relative;
    width: 10px;
    height: 5px;
    // float: right;
    // padding-right: 15px;
    // top: 10px;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 10px;    
    content: url("../assets/images/arrow-light-mode.png");
  }

  &-arrow-up {
    // position: relative;
    width: 10px;
    height: 5px;
    // float: right;
    // padding-right: 15px;
    // top: 10px;
    margin-left: auto;
    display: flex;
    align-items: center;
    // padding: 10px 10px 10px 10px;    
    padding: 0px 10px 0px 10px;    
    content: url("../assets/images/arrow-light-mode-up.png");
  }


  &-add {
    display: inline-block;
    padding: 5px 10px 5px 0px;
    font-size: 14px;
    color: variables.$lighter_text;
  }
 
  &-selected {
    display: inline-block;
    max-width: 260px;
    //margin-left: 10px;
    border-radius: 2px;
    // background-color: variables.$tags;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-item {
      display: inline-block;
      padding: 5px 2px;
      border-radius: 3px;
      // color: variables.$blue_tag;
      font-size: 14px;
      font-weight: 400;
      color: #333;
    }

    &-item:first-child {
      padding-left: 0px !important;
    }
  }
  &-input {
    background: white;
    padding: 10px;

    &--border {
      position: relative;
    }
    .tag-list-filter {
      &:focus {
        outline: none;
        border: 2px solid #0f3beb;
        border-radius: 5px;
      }
    }
  }
  &-load {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    color: variables.$middle_light_text;
    cursor: pointer;
  }
  &-dropdown {
    position: absolute;
    width: 100%;
    top: 40px;
    background: white;
    box-shadow: 0 5px 7px #ccc;
    z-index: 2;
    font-size: 14px;
    border-radius: 2px;
    &--content {
      height: 217px;
      overflow-y: auto;
    }
  }
  &-filter {
    width: 100%;
    height: 40px;
    padding: 10px 32px 10px 10px;
    border: 1px solid variables.$border;
    border-radius: 2px;

    &__clear {
      position: absolute;
      right: 10px;
      top: 14px;
      height: 12px;
      width: 12px;
      content: url("../assets/images/x.png");
      cursor: pointer;
    }
  }
  &-checked {
    margin-top: 2px;
    margin-left: 1px;
    // content: url("../assets/images/checked.png");
    &-hidden {
      margin-top: 2px;
      margin-left: 1px;
      // content: url("../assets/images/checked.png");
      visibility: hidden;
    }
  }
  &-checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid variables.$border;
  }
  &-item {
    font-size: 14px;
    // line-height: 16px;
    margin-left: 10px;
    color: variables.$middle_light_text;
    &-row {
      padding: 7px 15px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1;
      &:hover {
        background-color: variables.$entries_header;
      }
    }
  }
  &--not_tags {
    margin-left: 10px;
  }
  &__create-tag {
    position: absolute;
    display: flex;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 17px;
    bottom: 0;
    width: 320px;
    background: white;
    box-shadow: 0 -5px 10px rgba(0,0,0,0.1);
    cursor: pointer;
    &--icon {
      width: 19px;
      height: 19px;
      margin-right: 7px;
      margin-left: 10px;
      // content: url("/assets/images/create.png");
    }
    &--text {
      color: variables.$blue;
      font-size: 14px;
      // line-height: 16px;
    }
  }
  &__bottom-padding {
    padding-bottom: 65px;
    width: 100%;
  }
  &__create-form {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 240px;
    background: variables.$input-background;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    text-align: center;
    align-items: center;
    &__title-and-close {
      display: flex;
      margin-bottom: 20px;
      width: 100%;
    }
    &__close {
      position: absolute;
      right: 20px;
      top: 25px;
      height: 12px;
      width: 12px;
      content: url("../assets/images/x.png");
      cursor: pointer;
    }
    &--open {
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
    }
    &--divider {
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted variables.$border;
      margin-bottom: 20px;
    }
    &--title {
      margin-top: 20px;
      margin-left: 20px;
      font-size: 18px;
      // line-height: 21px;
      color: variables.$middle_light_text;
    }
    &--tag-name {
      margin-bottom: 20px;
      padding-left: 10px;
      border: 1px solid variables.$border;
      border-radius: 2px;
      width: 280px;
      height: 40px;
      &::placeholder {
        color: variables.$lighter_text;
      }
      &:focus {
        outline: none;
        border: 2px solid #0f3beb;
        border-radius: 5px;
      }
    }
    &--confirmation_button {
      margin-bottom: 20px;
      padding-top: 12px;
      width: 280px;
      height: 28px;
      border-radius: 2px;
      background: variables.$blue;
      color: variables.$input-background;
      font-size: 14px;
      // line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }

    &--cancel {
      font-size: 14px;
      // line-height: 16px;
      color: variables.$blue;
      cursor: pointer;
    }
  }

  // .tag-list-button {
  //   padding: 5px 0 !important;
  //   background: white !important;
  //   border: 0px solid #C6D2D9 !important;
  //   border-radius: 2px !important;
  //   cursor: pointer !important;
  //   align-items: center !important;
  //   display: flex !important;

  //   &-disabled {
  //     background: #eff4f7 !important;
  //     border: 1px solid #C6D2D9 !important; 
  //     cursor: default !important;
  //   }
  // }
 
}


